//---------------
// Blog Page
//---------------

// PACKAGES
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

// COMPONENTS
import Logo from "../assets/logos/ie-base-code.svg"
import MenuBack from "../assets/icons/menu-back.svg"
import Image from "../components/atoms/Image"

// COMPONENT BUILD
const BlogPage = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                sort: { fields: [frontmatter___date], order: ASC }
                filter: { frontmatter: { type: { eq: "blog" } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            type
                            title
                            date(formatString: "YYYY.MMMM.DD, dddd")
                            cover {
                                childImageSharp {
                                  fluid(maxHeight: 1200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                  }
                                }
                              }
                            author   
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    // JSX TEMPLATE
    return (
        <div>
            <Helmet defer={false} defaultTitle='Informeasy | House manual booklet for vacation rentals'>
                <html lang='en' />
                <meta name="viewport" content="initial-scale=1, shrink-to-fit=no, user-scalable=no, width=device-width"/>
                <meta name="robots" content="noindex" />
                <noscript>Please eneable Javascript or use a modern web browser!</noscript>
            </Helmet>
            <nav className="navbar px-4 fixed-top navbar-expand-md navbar-light bg-white border-bottom border-ie-lighter py-2 align-self-autopy-md-3">
                        <div className="container">
                            <Link to="/">
                                <img
                                    className="logo-size"
                                    src={Logo}
                                    alt="Informeasy"
                                />
                            </Link>
                            <button
                                className="navbar-toggler border-0 px-2 py-1"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navigations-08"
                                aria-controls="navigations-08"
                                aria-expanded="false"
                                aria-label="Toggle menu">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div
                                className="collapse navbar-collapse"
                                id="navigations-08">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link
                                            className="nav-link menu-link mr-md-5 py-6 py-md-2"
                                            to="/">
                                            <img
                                                className="d-inline-block position-relative pb-1 mr-3"
                                                src={MenuBack}
                                                alt="Arrow-icon"
                                            />
                                            Visit homepage
                                        </Link>
                                    </li>
                                </ul>
                            </div> 
                        </div>
                    </nav>
            <section className="pt-5 pb-7 pb-lg-10">
                <div className="container px-4 text-center mb-n11 text-center pt-8 pt-md-10">
                    <h2 className="mb-8 mb-lg-10 text-center underline">
                        <h1>Blog</h1>    
                    </h2>
                    <div className="text-left">
                        <ul style={{listStyleType: "none", margin: "0", padding: "0"}}>
                            {data.allMarkdownRemark.edges.map(edge => {
                                return (
                                    <li className="border bg-white">
                                        <Link to={`/blog/${edge.node.fields.slug}`}>
                                            <div className="d-flex align-items-center">
                                                <Image
                                                    placeholderStyle={{
                                                        filter: `blur(5px)`,
                                                    }}
                                                    style={{
                                                        borderWidth: `3px`,
                                                        borderStyle: `solid`,
                                                        borderColor: `white`,
                                                        width: `150px`,
                                                        height: `140px`,
                                                        objectFt: `cover`,
                                                        
                                                    }}
                                                    source={edge.node.frontmatter.cover.childImageSharp.fluid}
                                                    altText={edge.node.frontmatter.title}
                                                />
                                            <div className="ml-0 ml-lg-3 p-3">
                                                <h4>{edge.node.frontmatter.title}</h4>
                                                <div className="d-flex flex-column flex-md-row">
                                                    <p className="mb-0 mr-1">{edge.node.frontmatter.date} — </p>
                                                    <p className="mb-0">{edge.node.frontmatter.author}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BlogPage
